import React from 'react'
import Project from './project'
// import PortableText from '../../serializers/portableText'
import Container from '../../container'
import styles from '../page.module.css'

const JobsPage = ({title, _rawBody, _rawAwards, testimonialCredit, testimonialContent, testimonialImage, location, projectCategories, completionDate}) => {
  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>

          {/* project */}
          <Project
            _rawBody={_rawBody}
            _rawAwards={_rawAwards}
            location={location}
            projectCategories={projectCategories}
            completionDate={completionDate}
            testimonialCredit={testimonialCredit}
            testimonialContent={testimonialContent}
            testimonialImage={testimonialImage}
          />

        </Container>
      </article>
    </>
  )
}
export default JobsPage

import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import PortableText from '../../serializers/portableText'
import LeftChevron from '../../../images/icons/left-chevron.svg'

import styles from './project.module.css'

const Post = ({_rawBody, _rawAwards, testimonialCredit, testimonialContent, testimonialImage, location, projectCategories, completionDate}) => {
  // console.log({testimonialContent})
  return (
    <>
      <div className={styles.projectWrapper}>
        {location && (<h2 className={styles.location}>{location}</h2>)}
        {completionDate && (<div className={styles.completionDate}><p>{completionDate}</p></div>)}

        <div className={styles.projectContentWrapper}>

          <div className={styles.projectContent}>
            {_rawBody && (<div className={styles.bodyWrapper}> <PortableText blocks={_rawBody} /> </div>)}

            {_rawAwards && (<div className={styles.awardsWrapper}>
              <h3>Awards</h3>
              <PortableText blocks={_rawAwards} />
            </div>)}

            {projectCategories && (<div className={styles.servicesWrapper}>
              <h3>Services Provided</h3>
              <ul className={styles.serviceList}>
                {projectCategories.map((cat, i) => (
                  <li key={i} className={styles.service}>{cat}</li>
                ))}
              </ul>
            </div>)}

          </div>

          {/* Testimonial */}
          {testimonialContent && (<div className={styles.testimonialWrapper}>

            <h3>Testimonial</h3>

            <div className={styles.testimonialContentWrapper}>

              {testimonialImage && (<div className={styles.testimonialImage}><Img fluid={testimonialImage.asset.fluid} alt={testimonialImage.alt} /></div>)}

              <div className={styles.innerTestimonialContentWrapper}>
                {testimonialContent && (<div className={styles.testimonialContentText}><PortableText blocks={testimonialContent} /> </div>)}
                {testimonialCredit && (<div className={styles.testimonialCredit}>- {testimonialCredit}</div>)}
              </div>

            </div>

          </div>)}

        </div>

        <p> <Link to={`/portfolio`} className={styles.moreinfoBtn}><span><LeftChevron /> Back to Portfolio</span></Link></p>

      </div>
    </>

  )
}
export default Post
